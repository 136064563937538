<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiDatabaseEdit }}
          </v-icon>
          <span>Edit</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <!-- Dialog Content -->
    <product-review-form
      v-if="resource"
      ref="formRef"
      :resource="resource"
      :loading="loading"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #title>
        {{ $t('Edit ProductReview') }}
      </template>
    </product-review-form>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiDatabaseEdit, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { productReviewService } from '../../services'
import ProductReviewForm from './ProductReviewForm.vue'

export default {
  components: {
    ProductReviewForm,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      loading.value = true

      await productReviewService.update(props.resource.id, {
        ...data,
      }).then(() => {
        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: 'product review has been updated successfully.',
        })
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onSubmit,

      loading,

      icons: {
        mdiDatabaseEdit,
        mdiRefresh,
      },
    }
  },
}
</script>
