var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formRef",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-sm-h5 d-flex justify-space-between"},[_vm._t("title",function(){return [_vm._v(" Resource ")]}),_c('v-icon',{on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],2),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Content"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.badgeOpton,"label":"Badge","clearable":"","chips":""},model:{value:(_vm.form.badge),callback:function ($$v) {_vm.$set(_vm.form, "badge", $$v)},expression:"form.badge"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
              '1.00',
              '1.50',
              '2.00',
              '2.50',
              '3.00',
              '3.50',
              '4.00',
              '4.50',
              '5.00'
            ],"label":"Rating","rules":[_vm.validators.required]},model:{value:(_vm.form.rating),callback:function ($$v) {_vm.$set(_vm.form, "rating", $$v)},expression:"form.rating"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"success","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" Confirm ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }