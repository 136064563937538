<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- Status -->
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.status === 'published' ? 'success' : 'warning'"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <!-- Rating -->
      <template #[`item.rating`]="{ item }">
        <div class="d-flex align-center">
          <v-rating
            small
            readonly
            dense
            :value="parseFloat(item.rating)"
            color="yellow darken-3"
          />
          <span class="ml-1">{{ item.rating }}</span>
          <small
            v-show="item.original_rating !== item.rating"
            class="ml-1"
          >({{ item.original_rating }})</small>
        </div>
      </template>

      <!-- Badge -->
      <template #[`item.badge`]="{ item }">
        <v-chip
          v-if="item.badge"
          small
        >
          {{ item.badge }}
        </v-chip>
      </template>

      <!-- Content -->
      <template #[`item.content`]="{ item }">
        <div style="width: 450px; text-wrap: balance">
          <b>{{ item.user.profile.first_name }}, {{ item.user.profile.last_name }}</b>:
          {{ item.content }}
        </div>
      </template>

      <!-- Product -->
      <template #[`item.product_id`]="{ item }">
        <router-link
          style="width: 250px; text-wrap: balance"
          :to="{ name: 'product-view', params: { id: item.product.id } }"
          class="d-block text--primary font-weight-semibold cursor-pointer text-decoration-none"
        >
          {{ t(item.product.title) }}
          <!-- -
          {{ t(item.sku.name) }} -->
        </router-link>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="resource">
                <approval-dialog
                  v-if="$can('product_review:update', 'product_review_management') && item.status === 'pending'"
                  :resource="resource"
                  @updated="paginate"
                />
                <edit-dialog
                  v-if="$can('product_review:update', 'product_review_management')"
                  :resource="resource"
                  @updated="paginate"
                />
                <unpublish-dialog
                  v-if="$can('product_review:update', 'product_review_management') && item.status === 'published'"
                  :resource="resource"
                  @updated="paginate"
                />

                <delete-dialog
                  v-if="$can('product_review:delete', 'product_review_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>

              <!-- Required to hold some space -->
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDelete, mdiDotsVertical } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ApprovalDialog from './ApprovalDialog.vue'
import DeleteDialog from './DeleteDialog.vue'
import EditDialog from './EditDialog.vue'
import UnpublishDialog from './UnpublishDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    EditDialog,
    UnpublishDialog,
    DeleteDialog,
    ApprovalDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Status', value: 'status' },
      { text: 'Rating (Original)', value: 'rating' },
      { text: 'Badge', value: 'badge' },
      { text: 'User: Content', value: 'content', sortable: false },
      { text: 'Product', value: 'product_id', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiDelete,
      },
    }
  },
}
</script>
