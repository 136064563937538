var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.status === 'published' ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-rating',{attrs:{"small":"","readonly":"","dense":"","value":parseFloat(item.rating),"color":"yellow darken-3"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item.rating))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(item.original_rating !== item.rating),expression:"item.original_rating !== item.rating"}],staticClass:"ml-1"},[_vm._v("("+_vm._s(item.original_rating)+")")])],1)]}},{key:"item.badge",fn:function(ref){
var item = ref.item;
return [(item.badge)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.badge)+" ")]):_vm._e()]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"450px","text-wrap":"balance"}},[_c('b',[_vm._v(_vm._s(item.user.profile.first_name)+", "+_vm._s(item.user.profile.last_name))]),_vm._v(": "+_vm._s(item.content)+" ")])]}},{key:"item.product_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"d-block text--primary font-weight-semibold cursor-pointer text-decoration-none",staticStyle:{"width":"250px","text-wrap":"balance"},attrs:{"to":{ name: 'product-view', params: { id: item.product.id } }}},[_vm._v(" "+_vm._s(_vm.t(item.product.title))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadResource(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.resource)?[(_vm.$can('product_review:update', 'product_review_management') && item.status === 'pending')?_c('approval-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e(),(_vm.$can('product_review:update', 'product_review_management'))?_c('edit-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e(),(_vm.$can('product_review:update', 'product_review_management') && item.status === 'published')?_c('unpublish-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e(),(_vm.$can('product_review:delete', 'product_review_management'))?_c('delete-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()]:_c('v-list-item',{staticStyle:{"min-width":"100px"}})],2)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }